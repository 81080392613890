import createSlider from '../../../javascripts/utils/createSlider';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

const $$imageSlider = document.querySelectorAll<HTMLElement>('.slider');

$$imageSlider.forEach(($imageSlider) => {
  const $slides = $imageSlider.querySelector<HTMLDivElement>('.slider__slides');

  if (!$slides || $slides.querySelectorAll('.slider__slide').length <= 1) {
    return;
  }

  const slider = createSlider($slides, '.slider__slide');

  $imageSlider
    .querySelector('.slider__navigation-button--prev')
    ?.addEventListener('click', ({ currentTarget }) => {
      invisibleFocus(<HTMLButtonElement>currentTarget);
      slider.move('prev');
    });

  $imageSlider
    .querySelector('.slider__navigation-button--next')
    ?.addEventListener('click', ({ currentTarget }) => {
      invisibleFocus(<HTMLButtonElement>currentTarget);
      slider.move('next');
    });

  $imageSlider
    .querySelectorAll<HTMLButtonElement>('.slider__navigation-dot')
    .forEach(($dot, index) => {
      $dot.addEventListener('click', () => {
        const direction = slider.getCurrentSlide() <= index ? 'next' : 'prev';
        slider.moveTo(index, direction);
      });

      slider.onUpdate((currentSlide) => {
        $dot.dataset.active = currentSlide === index ? 'true' : 'false';
      });
    });
});
