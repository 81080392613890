import { createFocusTrap, FocusTrap } from 'focus-trap';
import { on } from 'delegated-events';
import { Instance } from '@popperjs/core';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import createPopper, {
  sameWidth,
} from '../../../javascripts/utils/createPopper';

let focusTrap: FocusTrap;
let popper: Instance;

on('click', '.dropdown__button', (event) => {
  const { currentTarget: $button } = event;
  const $container = $button.closest<HTMLElement>('.dropdown');
  const $children = $container?.querySelector<HTMLElement>('.dropdown__items');

  if ($container && $children) {
    event.preventDefault();

    if (!$children.hidden) {
      focusTrap?.deactivate();
    } else {
      $children.hidden = false;

      popper = createPopper($button, $children, {
        placement: 'bottom-start',
        strategy: 'fixed',
        modifiers: [sameWidth],
      });

      focusTrap = createFocusTrap($container, {
        onDeactivate() {
          $children.hidden = true;
          popper?.destroy();
          invisibleFocus($button);
          $button.classList.remove('button--active');
        },
        initialFocus: '.dropdown__items',
        clickOutsideDeactivates: true,
        returnFocusOnDeactivate: false,
        preventScroll: false,
      });

      $button.classList.add('button--active');
      focusTrap.activate();
    }
  }
});
