import { popperGenerator } from '@popperjs/core/lib/popper-lite';
import { Modifier } from '@popperjs/core';

import applyStyles from '@popperjs/core/lib/modifiers/applyStyles';
import computeStyles from '@popperjs/core/lib/modifiers/computeStyles';
import popperOffsets from '@popperjs/core/lib/modifiers/popperOffsets';
import eventListeners from '@popperjs/core/lib/modifiers/eventListeners';
import offset from '@popperjs/core/lib/modifiers/offset';
import flip from '@popperjs/core/lib/modifiers/flip';
import arrow from '@popperjs/core/lib/modifiers/arrow';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

export const sameWidth: Modifier<'sameWidth', unknown> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn({ state }) {
    // eslint-disable-next-line no-param-reassign
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect({ state }) {
    if (state.elements.reference instanceof HTMLElement) {
      // eslint-disable-next-line no-param-reassign
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    }
  },
};

const createPopper = popperGenerator({
  defaultModifiers: [
    eventListeners,
    applyStyles,
    computeStyles,
    popperOffsets,
    flip,
    offset,
    arrow,
    preventOverflow,
  ],
});

export default createPopper;
