import 'loading-attribute-polyfill/dist/loading-attribute-polyfill.module';

document.body.addEventListener(
  'load',
  ({ target: $image }) => {
    if (
      $image &&
      $image instanceof HTMLImageElement &&
      $image.classList.contains('image__img')
    ) {
      $image.classList.add('image__img--loaded');
    }
  },
  true,
);
