import { on } from 'delegated-events';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import moveFocus from '../../../javascripts/utils/moveFocus';
import collapse from '../../../javascripts/utils/collapse';
import onLostFocus from '../../../javascripts/utils/onLostFocus';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

let currentLostFocusHandler: CallableFunction | null = null;
let focusTrap: FocusTrap | null = null;

on(
  'click',
  '.header__navigation-item .header__navigation-link[aria-controls]',
  async (event) => {
    event.preventDefault();

    const { currentTarget: $trigger } = event;
    const { isOpen, $target } = await collapse($trigger);

    // Focus on opening
    if (isOpen) {
      moveFocus($target);
      currentLostFocusHandler = onLostFocus($target, () =>
        collapse($trigger, false),
      );
    } else {
      currentLostFocusHandler?.();
    }
  },
);

on('click', '.js-toggle-search', async (event) => {
  event.preventDefault();

  const { currentTarget: $trigger } = event;
  const { isOpen, $target: $search } = await collapse($trigger);

  // On open
  if (isOpen) {
    focusTrap = createFocusTrap($search, {
      initialFocus: 'input',
      clickOutsideDeactivates(e) {
        if (e.target instanceof Node) {
          if ($search.contains(e.target) || $trigger.contains(e.target)) {
            return false;
          }
        }

        return true;
      },
      allowOutsideClick(e) {
        if (e.target instanceof Node) {
          if ($trigger.contains(e.target)) {
            return true;
          }
        }

        return false;
      },
      returnFocusOnDeactivate: false,
      onPostDeactivate() {
        invisibleFocus($trigger);
      },
      onDeactivate: () => {
        collapse($trigger, false);
      },
    });

    focusTrap.activate();
  } else {
    focusTrap?.deactivate();
    focusTrap = null;
  }
});

on('click', '.js-toggle-navigation', async (event) => {
  event.preventDefault();

  const { currentTarget: $trigger } = event;
  const { isOpen, $target: $navigation } = await collapse($trigger);

  if (isOpen) {
    focusTrap = createFocusTrap($navigation, {
      initialFocus: false,
      returnFocusOnDeactivate: false,
      clickOutsideDeactivates(e) {
        if (e.target instanceof Node) {
          if ($navigation.contains(e.target) || $trigger.contains(e.target)) {
            return false;
          }
        }

        return true;
      },
      allowOutsideClick(e) {
        if (e.target instanceof Node) {
          if ($trigger.contains(e.target)) {
            return true;
          }
        }

        return false;
      },
      onActivate() {
        const $firstLink = $navigation.querySelector<HTMLAnchorElement>(
          '.header__navigation-link',
        );

        if ($firstLink) {
          invisibleFocus($firstLink);
        }
      },
      onPostActivate() {
        const $navigationItems = $navigation.querySelector<HTMLElement>(
          '.header__navigation-items',
        );

        if ($navigationItems) {
          disableBodyScroll($navigationItems);
        }
      },
      onPostDeactivate() {
        invisibleFocus($trigger);

        const $navigationItems = $navigation.querySelector<HTMLElement>(
          '.header__navigation-items',
        );

        if ($navigationItems) {
          enableBodyScroll($navigationItems);
        }
      },
      onDeactivate: () => {
        collapse($trigger, false);
        $navigation.classList.remove('header__navigation--open');
      },
    });

    $navigation.classList.add('header__navigation--open');
    focusTrap.activate();
  } else {
    focusTrap?.deactivate();
    focusTrap = null;
  }
});

on('click', '.js-close-navigation', (event) => {
  event.preventDefault();

  const { currentTarget: $closeTrigger } = event;

  $closeTrigger
    .closest('.header')
    ?.querySelector<HTMLAnchorElement>('.js-toggle-navigation')
    ?.click();
});
